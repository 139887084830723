$fandi-super-admin: #892c89;
$fandi-worker: #ab8e00;
$fandi-interim-agency: #209C80;
$fandi-employer: #2198d2;
$fandi-guest: #3d7a8a;

// Change Bulma defaults

@use "node_modules/bulma/sass" with ($custom-colors: ("super-admin": ($fandi-super-admin),
    "employer": ($fandi-employer),
    "interim-agency": ($fandi-interim-agency),
    "worker": ($fandi-worker),
    "guest": ($fandi-guest),
  ),
  $message-body-padding: 0.5rem,
  $card-header-shadow: none,
  $footer-padding: 1rem,
);

@import "bulma/sass/utilities/initial-variables";
@import "bulma/sass/utilities/functions";
@import "bulma/sass/utilities/mixins";
@import "bulma/sass/utilities/controls";
@import "bulma/sass/base/minireset";
@import "bulma/sass/utilities/derived-variables";

//:root {
//  @each $name, $pair in $custom-colors {
//    --#{$name}: nth($pair, 1);
//    --#{$name}-light: nth($pair, 2);
//    --#{$name}-dark: nth($pair, 3);
//    --#{$name}-invert: nth($pair, 4);
//  }
//}

$themes: (
  superAdmin: super-admin,
  employerMember: employer,
  guest: guest,
  interimAgencyMember: interim-agency,
  worker: worker
);

@each $theme-name, $theme-variable in $themes {
  .#{$theme-name}-theme {
    --bulma-primary: var(--bulma-#{$theme-variable});
    --bulma-primary-h: var(--bulma-#{$theme-variable}-h);
    --bulma-primary-s: var(--bulma-#{$theme-variable}-s);
    --bulma-primary-l: var(--bulma-#{$theme-variable}-l);
    --bulma-primary-base: var(--bulma-#{$theme-variable});
    --bulma-primary-invert-l: var(--bulma-#{$theme-variable}-invert-l);
    --bulma-primary-invert: var(--bulma-#{$theme-variable}-invert);
    --bulma-primary-light-l: var(--bulma-#{$theme-variable}-light-l);
    --bulma-primary-light: var(--bulma-#{$theme-variable}-light);
    --bulma-primary-light-invert: var(--bulma-#{$theme-variable}-light-invert);
    --bulma-primary-dark-l: var(--bulma-#{$theme-variable}-dark-l);
    --bulma-primary-dark: var(--bulma-#{$theme-variable}-dark);
    --bulma-primary-dark-invert: var(--bulma-#{$theme-variable}-dark-invert);
    --bulma-primary-soft: var(--bulma-#{$theme-variable}-soft);
    --bulma-primary-soft-invert: var(--bulma-#{$theme-variable}-soft-invert);
    --bulma-primary-bold: var(--bulma-#{$theme-variable}-bold);
    --bulma-primary-bold-invert: var(--bulma-#{$theme-variable}-bold-invert);
    --bulma-primary-on-scheme: var(--bulma-#{$theme-variable}-on-scheme);
    --bulma-link: var(--bulma-#{$theme-variable});
    --bulma-link-h: var(--bulma-#{$theme-variable}-h);
    --bulma-link-s: var(--bulma-#{$theme-variable}-s);
    --bulma-link-l: var(--bulma-#{$theme-variable}-l);
  }
}


/* Bulma CSS Tooltips*/
@import 'node_modules/@creativebulma/bulma-tooltip/dist/bulma-tooltip';

span[data-tooltip] {
  border-bottom: none;
}

input[type='date']::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}

input,
textarea {
  font-family: $family-monospace;
}

.summary-table {
  .table-container {
    padding-bottom: 0.75rem; // The scrollbar doesn't overlap the table text

    .table {
      border-collapse: separate; // Preserver borders even for td's that are position: sticky

      thead {
        tr:not(:first-child) {
          font-size: $size-7;
        }
      }

      td,
      th {
        &.table-section-start {
          border-left: 2px solid #dbdbdb !important;
        }

        &.table-section-end {
          border-right: 2px solid #dbdbdb !important;
        }

        &.table-section-end-sticky {
          border-right: 4px solid #dbdbdb !important;
        }
      }

      tbody {
        tr {
          font-size: $size-7;

          td {
            vertical-align: middle;
          }

          &:last-child {
            td {
              border-bottom: 2px solid #dbdbdb !important;
            }
          }
        }
      }

      .sticky {
        position: sticky !important;
        left: 0;
        top: 0;
        z-index: 1;
        background-color: white;
      }

      th.sticky {
        background-color: transparent;
      }
    }
  }
}

/* AUTOCOMPLETE DROPDOWN MENU */
.filter-dropdown {
  border-radius: 5px;
  border: 1px solid #ccc;

  li {
    border-bottom: 1px solid #ccc;
  }
}

/**
  * PoC Accordion using standard html <details />
  */

// Remove default + and - icons
summary {
  list-style: none
}

summary::-webkit-details-marker {
  display: none;
}

// Layout Styling
details {
  position: relative;
  flex: 1;
  border-radius: 4px;

  &>summary {
    margin: 0.5rem 1.5rem 0.5rem 0.5rem;
  }

  &[open] {

    summary {
      padding-bottom: 1.25rem;
      border-bottom: 1px solid #ededed;
      margin-bottom: 1.25rem;
    }
  }
}

// Style new expand/collapse icons
details summary::after {
  content: "+";
  position: absolute;
  right: 0;
  top: -6px;
  font-size: 2rem;
  color: $grey;
  cursor: pointer;
}

details[open] summary::after {
  content: "-";
  right: 3px;
  color: var(--bulma-primary);
}


/**
*   FILTERS AND FORMS VISUAL VALIDATION
*   use /dev/forms as super admin 
*   if you want to play with this
*/

.field.is-error {
  .control {
    .select__control, input, select {
      border-color: $danger !important;
      .select__indicator {
        color: $danger !important;
      }
    }
    .select {
      &::after {
        border-color: $danger;
      }
    }
  }
}

/**
* REACT SELECT
*/
.control {
  .select__control {
    cursor: pointer;
    border-width: 2px; // Big border

    &.is-not-empty {
      border-color: var(--bulma-primary) !important;

      .select__indicator {
        color: var(--bulma-primary) !important;
      }
    }
    &.is-empty {
      &:has(input[aria-required=true]) {
        border-color: $danger !important;
        .select__indicator {
          color: $danger !important;
        }
      }
    }
  }

  .select__menu,
  .select__menu-list,
  .select__option {
    z-index: 10 !important;
  }
}

/**
* Basic HTML Input fields
*/
input {
  border-width: 2px !important; // Big border

  &[value=""] {
    border-color: #B3B3B3;
  }

  &:not([value=""]) {
    border-color: var(--bulma-primary);
  }

  &:required {

    &:invalid,
    &[value=""],
    &:not(:placeholder-shown) {
      border-color: $danger !important;
    }

    &:valid {
      border-color: var(--bulma-primary) !important;
    }
  }
}

.select__indicator {
  color: var(--bulma-primary) !important;
}

.dropdown.is-small button:first-child {
  padding: 4px 6px !important;
  font-size: 0.75rem !important;

  & .dropdown-content {
    position: static;
    z-index: 1000;
  }
}

.overflow-visible {
  overflow: visible !important;
}
/**
* Basic HTML Select fields
*/
.select:not(.is-multiple) {
  &.is-empty {
    select {
      border-width: 2px;
      border-color: #B3B3B3;
      color: #808080;

      &:required {
        border-color: $danger;
      }
    }
    &:has(select[required])::after {
      border-color: $danger;
    }
    &::after {
      border-color: #B3B3B3;
      
    }
  }

  &.is-not-empty {
    select {
      border-width: 2px;
      border-color: var(--bulma-primary);
    }

    &::after {
      border-color: var(--bulma-primary);
    }
  }
}
